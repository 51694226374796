import { ChangeDetectionStrategy, Component, ElementRef, input, OnChanges, OnInit, output, signal, SimpleChanges, viewChild } from '@angular/core';
import { TOAST_TYPE, ToastService } from '../toast/toast.service';
import { FILE_TYPES } from '../../models/enum';
import { NgClass } from '@angular/common';

export interface FileUploaderProps {
  files?: File[],
  fileLimit?: number,
  disableDeleteFile?: boolean,
  allowedFormats: FILE_TYPES[]
}

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [NgClass],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileUploaderComponent implements OnInit, OnChanges {

  TOAST_TYPE = TOAST_TYPE;
  FILE_TYPES = FILE_TYPES;
  props = input.required<FileUploaderProps>();
  onFileAdded = output<File[]>();
  files = signal<File[]>([]);
  fileInput = viewChild.required<ElementRef<HTMLInputElement>>('fileInput');
  inputId = signal<string>(`app-switch-${Math.floor(Math.random() * Date.now()).toString(16)}`);
  supportedFormats = signal<string>('');

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    if (this.props().files?.length) {
      this.files.set(this.props().files!);
    }
    this.setSupportedFormats();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['props']) {
      if (this.props().files?.length) {
        this.files.set(this.props().files!);
      }
      this.setSupportedFormats();
    }
  }

  setSupportedFormats() {
    this.supportedFormats.set(this.props().allowedFormats.map(format => `.${format}`).join(', '))
  }

  emitUpdatedFiles(file: File) {
    if (file.size > 10 * 1024 * 1024) {
      this.toastService.showToast('ph-fill ph-warning', 'File size exceeds 10MB.', TOAST_TYPE.ERROR);
    } else {
      this.files.update(files => [...files, file]);
      this.onFileAdded.emit(this.files());
    }
  }
  
  updateFiles(file: File) {
    if (this.props().allowedFormats.includes(file.name.split('.').pop()?.toLowerCase() as FILE_TYPES)) {
      if (!this.files().some(currentFile => currentFile.name === file.name && currentFile.size === file.size && currentFile.type === file.type && currentFile.lastModified === file.lastModified)) {
        if (this.props().fileLimit) {
          if (this.files().length <= this.props().fileLimit! - 1) {
            this.emitUpdatedFiles(file);
          } else {
            this.toastService.showToast('ph-fill ph-warning', 'File limit reached.', TOAST_TYPE.ERROR);
          }
        } else {
          this.emitUpdatedFiles(file);
        }
      } else {
        this.toastService.showToast('ph-fill ph-warning', 'File already uploaded. Please choose another.', TOAST_TYPE.ERROR);
      }
    } else {
      this.toastService.showToast('ph-fill ph-warning', 'File not supported', TOAST_TYPE.ERROR);
    }
  }

  addFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      Array.from(input.files).forEach(file => {
        this.updateFiles(file);
      })
    }
    (event.target as HTMLInputElement).value = '';
  }

  onDrag(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  handleFiles(files: FileList) {
    Array.from(files).forEach((file) => {
      this.updateFiles(file);
    });
  }

  deleteFile(index: number) {
    this.fileInput().nativeElement.value = '';
    this.files.set(this.files().filter((_, i) => i !== index));
    this.onFileAdded.emit(this.files());
  }

}