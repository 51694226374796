<div class="file-upload-wrapper">
    
    <div class="upload-wrapper">
        <input type="file" #fileInput (change)="addFile($event)" [id]="inputId()" [multiple]="true" [accept]="supportedFormats()">
        <label [for]="inputId()" class="file-drop-container" (dragover)="onDrag($event)" (drop)="onDrop($event)">
          <i class="ph ph-cloud-arrow-up"></i>
          <div class="drag-text font-weight-600"><span>Click to upload</span> or drag and drop</div>
        </label>
    </div>

    @if (files().length) {
        <ul class="files-list">
            @for(file of files(); track file; let index = $index;) {

                @if(file.type.includes('pdf')){

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/pdf-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if (file.type.includes('image')) {

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/image-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if(file.type.includes('video')){

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/video-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if(file.type.includes('audio')){

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/audio-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if (file.type.includes(FILE_TYPES.CSV)) {

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/csv-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if(file.name.toLowerCase().endsWith(FILE_TYPES.XLSX)){

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/xls-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else if(file.name.toLowerCase().endsWith(FILE_TYPES.DOCX)){

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/doc-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>

                } @else {

                    <li>
                        <div class="file-list-item">
                            <img src="/assets/images/unknown-file.svg" alt="file">
                            {{file.name}}
                        </div>
                        <i class="ph-bold ph-trash" [ngClass]="props().disableDeleteFile ? 'disable' : 'file-delete'" (click)="deleteFile(index)"></i>
                    </li>
                }
                
            }
        </ul>
    }
</div>